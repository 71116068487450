































































































































































































































































































































































































import { Vue, Component } from 'vue-property-decorator';

@Component({
  head: {
    title() {
      return {
        inner: '개인정보처리방침 변경 내역'
      }
    }
  }
})
export default class Diff extends Vue {
  public created() {
    window.scrollTo(0, 0);
  }
  get isApp() {
    if (this.$route.query._from) return true;
    else return false;
  }
}
